import React from 'react'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router'

function PageOpener(
    { page , text, component, icon }
) {
    const history = useHistory()
  return (
    <Button type='link' variant='outline-default' 
            className='text-decoration-none text-dark' onClick={() => {
            history.push(page)
    }}> {icon} {text} {component && <span className='ms-2'>{component}</span>}</Button>
  )
}

export default PageOpener