import { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import { asyncWrap } from "../../utils/utils";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { BsFillCameraFill } from "react-icons/bs";
import { Image } from "react-bootstrap";
import PageOpener from "../PageOpener";
import { EditOutlined, HomeOutlined } from "@ant-design/icons";
import { useAuth } from "../../context/auth-context";

function ProfilePage() {
  const { t } = useTranslation();
  const history = useHistory();
  const [detailType, setDetailType] = useState(true);

  // eslint-disable-next-line
  const [ranking, setRanking] = useState();
  const [totalPoint, setTotalPoint] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    getUserProfile();
    getUsersEarnPoint();
  }, []);

  const getUserProfile = async () => {
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (error?.response?.status === 401) {
      Swal.fire("Please Logout and login again")
      return;
    }
    if (!result) {
      console.log(error);
      return;
    } else {
      setUser(result.data.data);
    }
  };

  const getUsersEarnPoint = async () => {
    const [error, result] = await asyncWrap(axios.get("/earnedPoints"));
    if (!result) {
      console.log(error);
      Swal.fire(`Couldn't got Points data`);
      return;
    }
    setTotalPoint(result.data.data.totalPoints[0].point);
  };  

  return (
    <div>
      <div className="mainPage" style={{ marginTop: "90px" }}>
        <StyledContainerDiv>
          {user?.profilePhotoPath ? (
            <img
              style={{
                borderRadius: "40%",
                boxShadow: "0px 3px 6px #0000001A",
                marginBottom: "10px",
              }}
              height="100"
              width="100"
              src={user?.profilePhotoPath}
              alt="profile"
            />
          ) : (
            <div
              onClick={() => history.push("/profile")}
              style={{ background: "#E5ECFD", width: "100px", height: "100px" }}
              className="rounded-circle mx-auto"
            >
              <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <BsFillCameraFill
                  style={{
                    fontSize: "30px",
                    color: "#3A69DD",
                  }}
                />
              </div>
            </div>
          )}
          <h3 className="mt-2 mb-0">{user?.fullName}</h3>
          <p className="text-muted">
            {user?.StateName}, {user?.districtName}
          </p>
        </StyledContainerDiv>

        <PointsContainerDiv>
          <PointsDataDiv>
            <h6 style={{ color: "#3A69DD", fontWeight: "600" }}>
              {totalPoint || 0}
            </h6>
            <h6>{t("total_points")}</h6>
          </PointsDataDiv>
          <PointsDataDiv>
            <h6 style={{ color: "#3A69DD", fontWeight: "600" }}>
              {ranking || 0}
            </h6>
            <h6>{t("rank")}</h6>
          </PointsDataDiv>
          <PointsDataDiv>
            <h6 style={{ color: "#3A69DD", fontWeight: "600" }}>
              {user?.UserLevel || 0}
            </h6>
            <h6>{t("user_level")}</h6>
          </PointsDataDiv>
        </PointsContainerDiv>

        {detailType ? (
          <div className="d-flex flex-column">
            
            <div
              style={{ color: "#3A69DD" }}
              className="fw-bold text-start mt-2 fs-6"
            >
              {t("personal_details")}
            </div>
            {user?.mobileNumber && (
              <div
                style={{ borderBottom: "0.5px solid #d1d1d1" }}
                className="d-flex flex-column text-start my-1 pb-1"
              >
                <div style={{ color: "#7B7B7B", fontSize: "14px" }}>
                  {t("mobile_no")}
                </div>
                <div
                  style={{
                    color: "#434343",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  {user?.mobileNumber}
                </div>
              </div>
            )}

            <div
              style={{ borderBottom: "0.5px solid #d1d1d1" }}
              className="d-flex flex-column text-start my-1 pb-1"
            >
              <div style={{ color: "#7B7B7B", fontSize: "14px" }}>
                {t("user_role")}
              </div>
              <div
                style={{
                  color: "#434343",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                {user?.roleId === "7" ? "Influencer" : user?.roleName}
              </div>
            </div>

            {user?.Email && (
              <div
                style={{ borderBottom: "0.5px solid #d1d1d1" }}
                className="d-flex flex-column text-start my-1 pb-1"
              >
                <div style={{ color: "#7B7B7B", fontSize: "14px" }}>
                  {t("email")}
                </div>
                <div
                  style={{
                    color: "#434343",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  {user?.Email}
                </div>
              </div>
            )}

            {user?.gender && (
              <div
                style={{ borderBottom: "0.5px solid #d1d1d1" }}
                className="d-flex flex-column text-start my-1 pb-1"
              >
                <div style={{ color: "#7B7B7B", fontSize: "14px" }}>
                  {t("gender")}
                </div>
                <div
                  style={{
                    color: "#434343",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  {user?.gender}
                </div>
              </div>
            )}

            {user?.dateOfBirth && (
              <div
                style={{ borderBottom: "0.5px solid #d1d1d1" }}
                className="d-flex flex-column text-start my-1 pb-1"
              >
                <div style={{ color: "#7B7B7B", fontSize: "14px" }}>
                  {t("birth_date")}
                </div>
                <div
                  style={{
                    color: "#434343",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  {moment(user?.dateOfBirth).format("DD MMM, YYYY") ===
                  "Invalid date"
                    ? ""
                    : moment(user?.dateOfBirth).format("DD MMM, YYYY")}
                </div>
              </div>
            )}

            {user?.aniversary && (
              <div
                style={{ borderBottom: "0.5px solid #d1d1d1" }}
                className="d-flex flex-column text-start my-1 pb-1"
              >
                <div style={{ color: "#7B7B7B", fontSize: "14px" }}>
                  {t("anniversary_date")}
                </div>
                <div
                  style={{
                    color: "#434343",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  {moment(user?.aniversary).format("DD MMM, YYYY") ===
                  "Invalid date"
                    ? ""
                    : moment(user?.aniversary).format("DD MMM, YYYY")}
                </div>
              </div>
            )}

            <div
              onClick={() => {
                setDetailType(false);
              }}
              style={{ color: "#3A69DD" }}
              className="fw-bold fs-6 mt-2"
            >
              {t("show_residential_details")}
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column">
            <div
              style={{ color: "#3A69DD" }}
              className="fw-bold text-start mt-2 fs-6"
            >
              {t("residential_details")}
            </div>
            <div
              style={{ borderBottom: "0.5px solid #d1d1d1" }}
              className="d-flex flex-column text-start my-1 pb-1"
            >
              <div style={{ color: "#7B7B7B", fontSize: "14px" }}>
                {t("country")}
              </div>
              <div
                style={{
                  color: "#434343",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                India
              </div>
            </div>

            {user?.StateName && (
              <div
                style={{ borderBottom: "0.5px solid #d1d1d1" }}
                className="d-flex flex-column text-start my-1 pb-1"
              >
                <div style={{ color: "#7B7B7B", fontSize: "14px" }}>
                  {t("state")}
                </div>
                <div
                  style={{
                    color: "#434343",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  {user?.StateName}
                </div>
              </div>
            )}

            {user?.districtName && (
              <div
                style={{ borderBottom: "0.5px solid #d1d1d1" }}
                className="d-flex flex-column text-start my-1 pb-1"
              >
                <div style={{ color: "#7B7B7B", fontSize: "14px" }}>
                  {t("city")}
                </div>
                <div
                  style={{
                    color: "#434343",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  {user?.districtName}
                </div>
              </div>
            )}

            {user?.pinCode && (
              <div
                style={{ borderBottom: "0.5px solid #d1d1d1" }}
                className="d-flex flex-column text-start my-1 pb-1"
              >
                <div style={{ color: "#7B7B7B", fontSize: "14px" }}>
                  {t("pincode")}
                </div>
                <div
                  style={{
                    color: "#434343",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  {user?.pinCode}
                </div>
              </div>
            )}

            {user?.address && (
              <div
                style={{ borderBottom: "0.5px solid #d1d1d1" }}
                className="d-flex flex-column text-start my-1 pb-1"
              >
                <div style={{ color: "#7B7B7B", fontSize: "14px" }}>
                  {t("address")}
                </div>
                <div
                  style={{
                    color: "#434343",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  {user?.address}
                </div>
              </div>
            )}

            <div
              onClick={() => {
                setDetailType(true);
              }}
              style={{ color: "#3A69DD" }}
              className="fw-bold fs-6 mt-2"
            >
              {t("show_personal_details")}
            </div>
          </div>
        )}

        {user?.IsAadharVerified ? (
          <div
            style={{ borderBottom: "1px solid #C7C7CC", paddingBottom: "15px" }}
            className="mt-3"
          >
            <h5
              style={{
                textAlign: "left",
                paddingLeft: "15px",
                marginBottom: "8px",
              }}
            >
              Aadhar Card Detail
            </h5>
            <StyledDataDiv>
              <p>Aadhar Card Number</p>
              <p>{user?.Aadharcardnumber}</p>
            </StyledDataDiv>
            <div className="mt-3">
              <Image
                src={user?.Aadharcardfrontimage}
                width="100%"
                height="200px"
              />
            </div>
          </div>
        ) : null}
        {user?.IsPanVerified ? (
          <div
            style={{ borderBottom: "1px solid #C7C7CC", paddingBottom: "15px" }}
            className="mt-3"
          >
            <h5
              style={{
                textAlign: "left",
                paddingLeft: "15px",
                marginBottom: "8px",
              }}
            >
              Pan Card Detail
            </h5>
            <StyledDataDiv>
              <p>Pan Card Number</p>
              <p>{user?.PancardNumber}</p>
            </StyledDataDiv>
            <div className="mt-3">
              <Image
                src={user?.PancardFrontimage}
                width="100%"
                height="200px"
              />
            </div>
          </div>
        ) : null}
      </div>
      <NavigationLinks />
    </div>
  );
}

const StyledContainerDiv = styled.div`
  border-radius: 50px 50px 0px 0px;
  margin-top: 50px;
  background-color: white !important;
  margin-top: -5%;
  z-index: 9;
`;
const PointsContainerDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 35px;
`;
const PointsDataDiv = styled.div`
  h6:nth-child(1) {
    color: #dc7832;
  }
  h6:nth-child(2) {
    color: #000000;
  }
`;

const StyledDataDiv = styled.div`
  p:nth-child(1) {
    color: #959595;
  }
  p:nth-child(odd) {
    margin-bottom: 0;
  }

  p:nth-child(even) {
    margin-bottom: 0;
  }
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 15px 0 15px;
`;

function NavigationLinks(){
  const {user} = useAuth();

  return (
    <div className="d-flex flex-row justify-content-center ">
      <PageOpener
        title={'HOME'}
        icon={<HomeOutlined />}
        page="/home"
      />
      <PageOpener 
        title={'EDIT'}
        icon={<EditOutlined />}
        page={"/edit-profile/"+user?.userId}
        />
    </div>
  );
}

export default ProfilePage;
